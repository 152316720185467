import React from 'react';
import { Typography } from '@mui/material';
import Seo from '../components/Seo';

const browser = typeof window !== 'undefined';
//

const NotFound = () => {
  return (
    browser && (
      <div>
        <Seo title="Stranica nije pronađena" />
        <Typography variant="h3">Not found</Typography>
      </div>
    )
  );
};

export default NotFound;
